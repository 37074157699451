import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { cleanSiteDescription } from '../modules/AirAPI/actions';

const StationDropdown = ({ selected_station, sites }) => {
	return (
		<Dropdown>
			<Dropdown.Toggle id='station-dropdown'>
				{cleanSiteDescription(selected_station.site_description)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{sites.valueSeq().map((site, idx) => {
					return (
						<LinkContainer
							to={`/station/${site.get('aqs_site_id')}`}
							key={`loc_${idx}`}
						>
							<Dropdown.Item>
								{cleanSiteDescription(site.get('site_description'))}
							</Dropdown.Item>
						</LinkContainer>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default StationDropdown;
