import React from 'react';
import { connect } from 'react-redux';

const DidYouKnowMsg = ({ pollutantMsg }) => {
	return (
		<div className='pollutant-msg'>
			<h3>Did you know?</h3>
			<p>{pollutantMsg}</p>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		pollutantMsg: store.rootState.getIn(['aqi', 'pollutant_strings', 'sources'])
	};
};

export default connect(mapStateToProps)(DidYouKnowMsg);
