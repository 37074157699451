import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { getAQIStrings } from '../modules/AQI/aqi';

export default class SitePin extends PureComponent {
	render() {
		const { aqi, stationId, idx, extraClass } = this.props;

		const status = aqi ? getAQIStrings(aqi).status : null;

		return (
			<Link to={`/station/${stationId}`} key={`loc_${idx}`}>
				<div
					className={`aqi-marker map-marker ${status || 'na'} ${extraClass}`}
				>
					<div className='aqi-marker-fg'>
						<p>{aqi || 'NA'}</p>
					</div>
				</div>
			</Link>
		);
	}
}
