export const ISSUES = {
	R: 'Asthma',
	LD: 'Lung Disease',
	HD: 'Heart Disease',
	COA: 'Children/Older Adults',
	AA: 'Active Adults'
};

const raw_pollutants = {
	O3: {
		name: 'Ozone',
		sources:
			'Ozone forms when two types of pollutants (VOCs and NOx) react in sunlight. These pollutants come from sources such as vehicles, industries, power plants, and products such as solvents and paints.',
		effects:
			'Ozone (O3) typically peaks in the late afternoon/early evening. Two air pollutants (VOCs and NOx) react in sunlight to create ozone.',
		actions: {
			good:
				"Don't allow VOCs to escape into the atmosphere un-checked. For example, check your gas cap regularly, don't leave gasoline containers open, and use only as much lighter fluid on your charcoal grill as necessary.",
			moderate:
				'Reduce unnecessary engine idling in your vehicle. Idling emits NOx and VOCs, which combine to form harmful ozone. Consider using mass transit or car-pooling to work.',
			high:
				'Reduce unnecessary engine idling in your vehicle. Idling emits NOx and VOCs, which combine to form harmful ozone. Avoid mowing your lawn with gas-powered equipment. Wait until evening to fill up your vehicle at the gas station. Consider using mass transit or car-pooling to work.'
		},
		issues: [ISSUES.R, ISSUES.LD, ISSUES.COA, ISSUES.AA],
		unit: 'ppb',
		avg: 8
	},
	'PM2.5': {
		name: 'Fine Particles',
		sources:
			'Major sources of fine particles include motor vehicles, power plants, residential wood burning, forest fires, agricultural burning, some industrial processes, and other combustion processes.',
		effects:
			'Fine particles (PM 2.5) are 2.5 micrometers in diameter or smaller and can only be seen with an electron microscope. They are produced from all types of combustion, including motor vehicles, power plants, residential wood burning, forest fires, agricultural burning, and industrial processes.',
		actions:
			'Reduce unnecessary engine idling in your vehicle. Consider switching from gas-powered lawn equipment to electric.  Consider using mass transit or car-pooling to work. Consider switching from a gas- or diesel-powered vehicle to electric or a hybrid. Walk or bike when you can.',
		issues: [ISSUES.LD, ISSUES.HD, ISSUES.COA],
		unit: 'µg/m3',
		avg: 24
	},
	PM10: {
		name: 'Coarse Particles',
		sources:
			'Coarse particles come from crushing and grinding operations, road dust, and some agricultural operations.',
		effects:
			'Coarse particles come from crushing and grinding operations, road dust, and some agricultural operations.',
		actions:
			'Reduce unnecessary vehicle use. Plant ground covering to reduce dust from your property. Spray water when cutting concrete to reduce dust. Wet down gravel parking lots and driveways to reduce dust from vehicle traffic.',
		issues: [ISSUES.LD, ISSUES.HD, ISSUES.COA],
		unit: 'µg/m3',
		avg: 24
	},
	CO: {
		name: 'Carbon Monoxide',
		sources:
			'Vehicle exhaust contributes roughly 75 percent of all carbon monoxide emissions nationwide, and up to 95 percent in cities. Other sources include fuel combustion in industrial processes and natural sources such as wildfires. Carbon monoxide levels typically are highest during cold weather because cold temperatures make combustion less complete and cause inversions that trap pollutants close to the ground.',
		effects:
			'Vehicle exhaust contributes roughly 75 percent of all carbon monoxide emissions nationwide, and up to 95 percent in cities. Other sources include fuel combustion in industrial processes and natural sources such as wildfires. Carbon monoxide levels typically are highest during cold weather because cold temperatures make combustion less complete and cause inversions that trap pollutants close to the ground.',
		actions:
			'Reduce unnecessary engine idling in your vehicle. Consider switching from gas-powered lawn equipment to electric.  Consider using mass transit or car-pooling to work. Consider switching from a gas- or diesel-powered vehicle to electric or a hybrid. Walk or bike when you can.',
		issues: [ISSUES.HD],
		unit: 'ppm',
		avg: 8
	},
	SO2: {
		name: 'Sulfer Dioxide',
		sources:
			'Sulfur dioxide, a colorless, reactive gas, is produced when sulfur-containing fuels such as coal and oil are burned. Generally, the highest levels of sulfur dioxide are found near large industrial complexes. Major sources include power plants, refineries, and industrial boilers.',
		effects:
			'Sulfur dioxide, a colorless, reactive gas, is produced when sulfur-containing fuels such as coal and oil are burned. Generally, the highest levels of sulfur dioxide are found near large industrial complexes. Major sources include power plants, refineries, and industrial boilers.',
		actions:
			'Reduce your electricity use at home. Weatherproof your home to make it more energy efficient. Consider installing solar panels.',
		issues: [ISSUES.R],
		unit: 'ppb',
		avg: 1
	},
	NO2: {
		name: 'Nitrogen Dioxide',
		sources:
			'Nitrogen Dioxide (NO2) is one of a group of highly reactive gases known as oxides of nitrogen or nitrogen oxides (NOx). Other nitrogen oxides include nitrous acid and nitric acid. NO2 is used as the indicator for the larger group of nitrogen oxides. NO2 primarily gets in the air from the burning of fuel. NO2 forms from emissions from cars, trucks and buses, power plants, and off-road equipment.',
		effects:
			'NO2 primarily gets in the air from the burning of fuel. NO2 forms from emissions from cars, trucks and buses, power plants, and off-road equipment. NO2 reacts with VOCs to create ozone.',
		actions:
			'Reduce unnecessary engine idling in your vehicle. Consider switching from gas-powered lawn equipment to electric.  Consider using mass transit or car-pooling to work. Consider switching from a gas- or diesel-powered vehicle to electric or a hybrid. Walk or bike when you can.',
		issues: [ISSUES.LD, ISSUES.COA],
		unit: 'ppb',
		avg: 1
	}
};

export const pollutants = {
	OZONE: raw_pollutants['O3'],
	'PM10(LC)': raw_pollutants['PM10'],
	'PM10(STD)': raw_pollutants['PM10'],
	PM25: raw_pollutants['PM2.5'],
	...raw_pollutants
};

export const getPollutantStrings = pollutant => {
	if (!pollutants.hasOwnProperty(pollutant)) {
		return null;
	}

	return pollutants[pollutant];
};
