import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const Skittles = () => {
	const statuses = {
		Good: { range: '0-50', label: 'Good' },
		Moderate: { range: '51-100', label: 'Moderate' },
		Low: {
			range: '101-150',
			label: 'Elevated',
			hover: 'Unhealthy for Sensitive Groups'
		},
		Unhealthy: { range: '151-200', label: 'Unhealthy' },
		Bad: { range: '201-300', label: 'Severe', hover: 'Very Unhealthy' },
		Hazardous: { range: '301-500', label: 'Hazardous' }
	};

	const renderLabel = (label, hover) => {
		if (!hover) {
			return <p className='skittle-lbl'>{label}</p>;
		}

		return (
			<OverlayTrigger
				trigger={['click', 'hover']}
				placement={'top'}
				overlay={<Popover>{hover}</Popover>}
			>
				<p className='skittle-lbl has-hover'>{label}</p>
			</OverlayTrigger>
		);
	};

	const renderSkittle = (status, label, hover) => {
		if (!hover) {
			return (
				<img
					src={require(`../img/skittles/${status.toLowerCase()}_skittle.png`)}
					alt={`${label} air quality range`}
				/>
			);
		}

		return (
			<OverlayTrigger
				trigger={['click', 'hover']}
				placement={'top'}
				overlay={<Popover>{hover}</Popover>}
			>
				<img
					src={require(`../img/skittles/${status.toLowerCase()}_skittle.png`)}
					alt={`${label} air quality range`}
					className='has-hover'
				/>
			</OverlayTrigger>
		);
	};

	const skittles = Object.keys(statuses).map((status, i) => {
		const { range, label, hover } = statuses[status];

		return (
			<div className='skittle' key={i}>
				{renderLabel(label, hover)}
				{renderSkittle(status, label, hover)}
				<p className='skittle-range' aria-label={`${status} air quality range`}>
					{range}
				</p>
			</div>
		);
	});

	return <div className='skittles'>{skittles}</div>;
};

export default Skittles;
