import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
	VictoryLine,
	VictoryChart,
	VictoryTheme,
	VictoryArea,
	VictoryAxis
} from 'victory';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import NProgress from 'nprogress';

import ExportIcon from '../img/export-icn.svg';

dayjs.extend(customParseFormat);

const MONTH_FORMAT = 'MMMM YYYY';

// colors
const good = '#00e400';
// const moderate = '#ffff00';
// const hazardous = '#7e0023';
const gray = '#e4e4e4';
const chartWidth = 500;
const chartHeight = 250;

const currentMonth = dayjs().format(MONTH_FORMAT);
const initialAQIState = {
	lastMonth: [
		{ day: 1, aqi: null },
		{ day: 30, aqi: null }
	],
	thisMonth: [
		{ day: 1, aqi: null },
		{ day: 30, aqi: null }
	]
};

/**
 * For a given month, get the formatted date of the first and last days
 */
const getMonthStartEnd = (startDate, monthsBack = 0) => ({
	start_date: dayjs(startDate, MONTH_FORMAT)
		.subtract(monthsBack, 'month')
		.startOf('month')
		.format('YYYY-MM-DD'),
	end_date: dayjs(startDate, MONTH_FORMAT)
		.subtract(monthsBack, 'month')
		.endOf('month')
		.format('YYYY-MM-DD')
});

/**
 * Filter aqis: if a day has multiple aqis, return only the highest one
 * @param {any[]} aqis
 * @returns Filtered array of AQIs
 */
const filterAQIs = aqis =>
	aqis && aqis.length > 0
		? Object.entries(
				aqis.reduce((acc, cur) => {
					acc[cur.date_observed] = acc[cur.date_observed]
						? Math.max(acc[cur.date_observed], cur.aqi)
						: cur.aqi;
					return acc;
				}, {})
		  ).map(([date, aqi]) => ({ day: dayjs(date).date(), aqi }))
		: initialAQIState.lastMonth;

/**
 * Calculate the chart's max Y domain
 */
const getMaxAQI = aqis =>
	aqis.map(r => r.aqi).reduce((acc, cur) => Math.max(acc, cur), 0);
const roundUp = num => (num > 0 ? Math.ceil(num / 80) * 80 : 100);

const createCSV = (data, fields, type) => {
	let csv = '';

	const replace = {
		O3: 'OZONE'
	};

	csv += fields.join(',');

	for (const item of data[type]) {
		let line = [];
		for (const field of fields) {
			let val = null;

			if (item.hasOwnProperty(field)) {
				val = item[field];
			} else if (item.parameter && item.parameter[field]) {
				val = item.parameter[field];
			}

			if (replace.hasOwnProperty(val)) {
				val = replace[val];
			}

			if (val === null || val === '') {
				val = 'NA';
			}

			line.push(val);
		}

		csv += '\n' + line.join(',');
	}

	return URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));
};

const createAQIExportFile = (data, isStation) => {
	let fields = isStation
		? ['date_observed', 'aqi', 'aqs_site_id', 'name']
		: ['date_observed', 'aqi', 'name'];

	return createCSV(data, fields, 'aqi');
};

const createReadingsExportFile = data => {
	let fields = [
		'date',
		'hour',
		'report_value',
		'aqs_method_code',
		'parameter_code',
		'parameter_desc',
		'parameter_name',
		'parameter_category_name',
		'unit_description',
		'unit_abbreviation'
	];

	return createCSV(data, fields, 'readings');
};

export default function HistoryGraphPanel({ stationId = null }) {
	const [aqis, setAQIs] = useState(initialAQIState);
	const [unfilteredAQIs, setUnfilteredAQIs] = useState({});
	const [maxDomain, setMaxDomain] = useState(roundUp(0));
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);
	const [isLoading, setIsLoading] = useState(true);

	const isStation = stationId != null;
	const baseUrl = isStation
		? `${process.env.API_BASE_URL}/aqi/${stationId}`
		: `${process.env.API_BASE_URL}/aqi/history`;

	//Load historical AQI data
	useEffect(() => {
		/**
		 * Whether we are in a stale fetch to be ignored
		 * @see {@link https://react.dev/reference/react/useEffect#fetching-data-with-effects}
		 */
		let ignore = false;

		const fetchData = month => {
			const getLastMonthData = () =>
				axios.get(baseUrl, {
					params: getMonthStartEnd(month, 1)
				});
			const getThisMonthData = () =>
				axios.get(baseUrl, {
					params: getMonthStartEnd(month)
				});
			NProgress.start();
			setIsLoading(true);
			axios
				.all([getLastMonthData(), getThisMonthData()])
				.then(
					axios.spread((lastMonthData, thisMonthData) => {
						let initialAQIs = {
							lastMonth: lastMonthData.data.data,
							thisMonth: thisMonthData.data.data
						};

						let filteredAQIs = {
							lastMonth: filterAQIs(initialAQIs.lastMonth),
							thisMonth: filterAQIs(initialAQIs.thisMonth)
						};

						setAQIs({
							lastMonth: filteredAQIs.lastMonth,
							thisMonth: filteredAQIs.thisMonth
						});

						setUnfilteredAQIs({
							lastMonth: initialAQIs.lastMonth,
							thisMonth: initialAQIs.thisMonth
						});

						setMaxDomain(
							roundUp(
								Math.max(
									getMaxAQI(filteredAQIs.lastMonth),
									getMaxAQI(filteredAQIs.thisMonth)
								)
							)
						);

						NProgress.done();
						setIsLoading(false);
					})
				)
				.catch(err => {
					console.error(err);
					NProgress.done();
					setIsLoading(false);
				});
		};

		if (!ignore) {
			fetchData(selectedMonth);
		}

		return () => {
			ignore = true;
		};
	}, [isStation, baseUrl, selectedMonth]);

	return (
		<section className={`history-graph-panel ${isStation ? 'is-station' : ''}`}>
			<div className='header'>
				<Title isStation={isStation} />
				<Legend isStation={isStation} selectedMonth={selectedMonth} />
				<div className='header-right'>
					<Datepicker
						selectedMonth={selectedMonth}
						setSelectedMonth={setSelectedMonth}
					/>
					<ExportLink
						isStation={isStation}
						stationId={stationId}
						unfilteredAQIs={unfilteredAQIs}
						setIsLoading={setIsLoading}
						selectedMonth={selectedMonth}
					/>
				</div>
			</div>
			<Error isLoading={isLoading} aqis={aqis} />
			<Chart isStation={isStation} aqis={aqis} maxDomain={maxDomain} />
		</section>
	);
}

/**
 * Render the panel title, based on whether it's a station
 */
const Title = ({ isStation }) => {
	return <h1>{isStation ? 'Station Data Look-up by Month' : 'AQI History'}</h1>;
};

/**
 * Render a legend of the selected months for the chart colors
 */
const Legend = ({ isStation, selectedMonth }) => {
	if (isStation) {
		return null;
	}

	return (
		<div className='labels'>
			<span className='month-label last-month' />
			<span>
				{dayjs(selectedMonth, MONTH_FORMAT)
					.subtract(1, 'month')
					.format(MONTH_FORMAT)}
			</span>
			<span className='month-label this-month' />
			<span>{dayjs(selectedMonth, MONTH_FORMAT).format(MONTH_FORMAT)}</span>
		</div>
	);
};

/**
 * Generate an array of formatted months from this month back.
 *
 * @param {number} monthsCount How many months back to generate, starting with this month (zero-indexed)
 * @param {string} dateTemplate Template for the dayjs formatter
 *
 * @returns Array of formatted date names
 */
const generatePastMonthNames = (monthsCount, dateTemplate) =>
	[...Array(monthsCount)].map((_, monthsBack) =>
		dayjs()
			.subtract(monthsBack, 'month')
			.format(dateTemplate)
	);

/**
 * Render a dropdown of months to select the month of data to inspect
 */
const Datepicker = ({ selectedMonth, setSelectedMonth }) => {
	const monthOptions = generatePastMonthNames(12, MONTH_FORMAT);

	return (
		<DropdownButton className='dropdown' title={selectedMonth}>
			{monthOptions.map(month => (
				<DropdownItem
					as='button'
					key={month}
					eventKey={month}
					onSelect={setSelectedMonth}
				>
					{month}
				</DropdownItem>
			))}
		</DropdownButton>
	);
};

/**
 * Renders a link to download a file of AQI data (if not a station)
 * or reading data (if a station). Fetches the readings if a station
 * id is provided.
 */
const ExportLink = ({
	isStation,
	stationId,
	unfilteredAQIs,
	setIsLoading,
	selectedMonth
}) => {
	const readingsBaseUrl = isStation
		? `${process.env.API_BASE_URL}/readings/${stationId}`
		: '';

	const [readings, setReadings] = useState(null);

	// Load historical Readings data (for export)
	useEffect(() => {
		/**
		 * Whether we are in a stale fetch to be ignored
		 * @see {@link https://react.dev/reference/react/useEffect#fetching-data-with-effects}
		 */
		let ignore = false;

		const fetchData = month => {
			const getLastMonthData = () =>
				axios.get(readingsBaseUrl, {
					params: getMonthStartEnd(month, 1)
				});
			const getThisMonthData = () =>
				axios.get(readingsBaseUrl, {
					params: getMonthStartEnd(month)
				});
			NProgress.start();
			setIsLoading(true);
			axios
				.all([getLastMonthData(), getThisMonthData()])
				.then(
					axios.spread((lastMonthData, thisMonthData) => {
						setReadings({
							lastMonth: lastMonthData.data.data.readings,
							thisMonth: thisMonthData.data.data.readings
						});
					})
				)
				.catch(err => {
					console.error(err);
					NProgress.done();
					setIsLoading(false);
				});
		};

		// Only load readings on the station page
		if (!ignore && isStation) {
			fetchData(selectedMonth);
		}

		return () => {
			ignore = true;
		};
	}, [setIsLoading, isStation, readingsBaseUrl, selectedMonth]);

	let exportFile = undefined;

	if (
		!isStation &&
		unfilteredAQIs != null &&
		unfilteredAQIs.hasOwnProperty('thisMonth')
	) {
		exportFile = createAQIExportFile(
			{
				aqi: unfilteredAQIs.thisMonth
			},
			isStation
		);
	}

	if (isStation && readings != null && readings.hasOwnProperty('thisMonth')) {
		exportFile = createReadingsExportFile({
			readings: readings.thisMonth
		});
	}

	const filename = isStation ? 'readings.csv' : 'aqidata.csv';

	return (
		<div className='export-links'>
			<ExportIcon />
			<a
				className='export'
				data-disabled={!exportFile ? 'true' : 'false'}
				download={exportFile ? filename : null}
				href={exportFile}
			>
				<span>{isStation ? 'Export Readings' : 'Export AQI'}</span>
			</a>
		</div>
	);
};

/**
 * Render an error message if no data is available for the selected months
 */
const Error = ({ isLoading, aqis }) => {
	return (
		<div
			className='error-message'
			data-visible={
				!isLoading &&
				aqis.lastMonth[0].aqi === null &&
				aqis.thisMonth[0].aqi === null
			}
		>
			No data is available for the selected months
		</div>
	);
};

/**
 * Render a Victory chart with a line for the current month
 * and an area for last month. Not rendered for stations.
 */
const Chart = ({ isStation, maxDomain, aqis }) => {
	if (isStation) {
		return null;
	}

	return (
		<div
			style={{
				position: 'relative',
				height: 0,
				width: '100%',
				padding: 0,
				paddingBottom: `${100 * (chartHeight / chartWidth)}%`
			}}
		>
			<VictoryChart
				domain={{ y: [0, maxDomain] }}
				theme={VictoryTheme.material}
				className='line-chart'
				width={chartWidth}
				height={chartHeight}
				style={{
					parent: {
						position: 'absolute',
						height: '100%',
						width: '100%',
						left: 0,
						top: 0
					}
				}}
				animate={{ duration: 1500 }}
			>
				<VictoryAxis
					dependentAxis
					style={{
						axis: { stroke: 'transparent' },
						axisLabel: {
							fontSize: 10,
							fill: gray,
							padding: 30
						},
						ticks: { stroke: 'transparent' },
						tickLabels: { fontSize: 8, fill: gray }
					}}
					label='AQI'
				/>
				<VictoryAxis
					style={{
						axisLabel: {
							fontSize: 10,
							fill: gray,
							padding: 25
						},
						tickLabels: { fontSize: 8, fill: gray }
					}}
					label='Day'
				/>
				<VictoryArea
					interpolation='natural'
					style={{ data: { fill: gray } }}
					data={aqis.lastMonth}
					x='day'
					y='aqi'
				/>
				<VictoryLine
					interpolation='natural'
					style={{
						data: { stroke: good },
						parent: { border: '1px solid #ccc' }
					}}
					data={aqis.thisMonth}
					x='day'
					y='aqi'
				/>
			</VictoryChart>
		</div>
	);
};
