import axios from 'axios';

export const GET_SITES = 'GET_SITES';
export const GET_AQI = 'GET_AQI';
export const UPDATE_SITES = 'UPDATE_SITES';
export const UPDATE_CURRENT_AQI = 'UPDATE_CURRENT_AQI';
export const UPDATE_TOP_AQI = 'UPDATE_TOP_AQI';
export const UPDATE_PARAMETERS = 'UPDATE_PARAMETERS';

export const getQueryAqi = () => {
	let q = window.location.href.match(/aqi=(\d+)$/);
	let newAQI = null;
	if (q) {
		newAQI = parseInt(q[1]);
	}

	return newAQI;
};

export const calcState = zip => {
	// 46001 - 47997, IN
	// 40003 - 42788, KY

	if (zip >= 46001 && zip <= 47994) {
		return 'IN';
	}

	if (zip >= 40003 && zip <= 42788) {
		return 'KY';
	}

	return 'NA';
};

export const cleanSiteDescription = desc => {
	desc = desc.replace(/Monitoring Station\s*(in|at)/, '');
	desc = desc.replace('Air Monitoring Station', '');
	desc = desc.replace(/\s*-\s*(.*?)$/, '');
	desc = desc.replace(/\s*,\s*(.*?)$/, '');
	desc = desc.replace(/\(.*?$/, '');
	desc = desc.replace('Louisville Police', 'LMPD');

	return desc;
};

export const getSites = () => {
	return {
		type: GET_SITES
	};
};

export const updateSites = sites => {
	return {
		type: UPDATE_SITES,
		sites
	};
};

export const updateTopAQI = aqi => {
	return {
		type: UPDATE_TOP_AQI,
		aqi
	};
};

export const updateCurrentAQI = aqi => {
	return {
		type: UPDATE_CURRENT_AQI,
		aqi
	};
};

export const updateParameters = parameters => {
	return {
		type: UPDATE_PARAMETERS,
		parameters
	};
};

export function fetchSites() {
	return axios.get(`${process.env.API_BASE_URL}/sites`);
}

export function fetchAQI() {
	return axios.get(`${process.env.API_BASE_URL}/aqi`);
}

export function fetchParameters() {
	return axios.get(`${process.env.API_BASE_URL}/parameters`);
}
