import { put, takeEvery, call } from 'redux-saga/effects';

import {
	GET_USER_LOCATION,
	fetchUserLocation,
	updateUserLocation
} from './actions';

function* handleLoadUserLocation() {
	const pos = yield call(fetchUserLocation);

	yield put(updateUserLocation(pos));
}

export function* watchGetUserLocation() {
	yield takeEvery(GET_USER_LOCATION, handleLoadUserLocation);
}
