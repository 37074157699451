import React from 'react';
import { connect } from 'react-redux';
import HeartIcon from '../img/populations/heart.svg';
import BabyAndPup from '../img/populations/baby_pup.svg';
import Shoe from '../img/populations/shoe.svg';
import Couple from '../img/populations/elderly_couple.svg';

const SensitivePopulationPanel = ({ status, issues, actions }) => {
	issues && issues.toJS();

	const healthIssues = ['Asthma', 'Lung Disease', 'Heart Disease'];
	const sensitiveMsg = actions
		? actions.find(act => act.get('type') === 'sensitive').get('action')
		: null;

	const renderMessage = () => (
		<span dangerouslySetInnerHTML={{ __html: sensitiveMsg }} />
	);

	return (
		<div className='sensitive-pops'>
			<div className='pop-icons'>
				<div
					className={`${
						issues && healthIssues.some(iss => issues.includes(iss))
							? 'pop-icon affected ' + status
							: 'pop-icon'
					}`}
				>
					<HeartIcon />
				</div>
				<div
					className={`${
						issues && issues.includes('Children/Older Adults')
							? 'pop-icon affected ' + status
							: 'pop-icon'
					}`}
				>
					<Couple />
				</div>
				<div
					className={`${
						issues && issues.includes('Children/Older Adults')
							? 'pop-icon affected baby ' + status
							: 'pop-icon baby'
					}`}
				>
					<BabyAndPup />
				</div>
				<div
					className={`${
						issues && issues.includes('Active Adults')
							? 'pop-icon affected ' + status
							: 'pop-icon'
					}`}
				>
					<Shoe />
				</div>
			</div>
			<h2>{issues && issues.join(', ')}</h2>
			<p>{renderMessage()}</p>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		issues: store.rootState.getIn(['aqi', 'pollutant_strings', 'issues']),
		actions: store.rootState.getIn(['aqi', 'aqi_strings', 'actions'])
	};
};

export default connect(mapStateToProps)(SensitivePopulationPanel);
