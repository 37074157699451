import React from 'react';
import { connect } from 'react-redux';
import ReactMapGL, { Marker } from 'react-map-gl';

import { MAPBOX_TOKEN, mapBounds } from '../config';
import SitePin from './SitePin';

class StationMap extends React.Component {
	// Default is to center on Louisville
	state = {
		viewport: {
			latitude: 38.160072,
			longitude: -85.524796,
			zoom: 8.65
		}
	};

	zoomToStation = () => {
		if (!this.props.selected_station) {
			return;
		}

		const {
			selected_station: { latitude, longitude }
		} = this.props;

		this.setState({
			viewport: {
				latitude: parseFloat(latitude),
				longitude: parseFloat(longitude),
				zoom: 11
			}
		});
	};

	componentDidMount() {
		this.zoomToStation();
	}

	componentDidUpdate(prevProps) {
		if (this.props.selected_station_id !== prevProps.selected_station_id) {
			this.zoomToStation();
		}
	}

	onViewportChange = viewport => {
		const {
			zoom: { min: minZoom },
			latitude: { min: minLat, max: maxLat },
			longitude: { min: minLng, max: maxLng }
		} = mapBounds;
		let { width, height, ...etc } = viewport;

		//Lock the map into our bounding box
		if (etc.longitude < minLng) {
			etc.longitude = minLng;
		} else if (etc.longitude > maxLng) {
			etc.longitude = maxLng;
		} else if (etc.latitude < minLat) {
			etc.latitude = minLat;
		} else if (etc.latitude > maxLat) {
			etc.latitude = maxLat;
		}

		//Don't let people zoom out too far
		if (etc.zoom < minZoom) {
			etc.zoom = minZoom;
		}

		this.setState({ viewport: etc });
	};

	renderSiteMarker = (site, index) => {
		const { selected_station_id, pollutantId, siteReadings } = this.props;
		const siteId = site.get('aqs_site_id');
		const siteLong = site.get('longitude');
		const siteLat = site.get('latitude');
		const hasToxics = site.get('has_toxics');
		const siteAqi = this.props.siteReadings.has(`${siteId}`)
			? siteReadings
					.get(siteId)
					.get(0)
					.get('aqi')
			: null;

		let extraClass = '';

		if (!siteLong || !siteLat || hasToxics) {
			return;
		}

		// mark the pin if the top pollutant isn't in the site's readings
		if (
			siteAqi &&
			site
				.get('parameters')
				.findIndex(param => param.get('name') === pollutantId) === -1
		) {
			extraClass = extraClass + ' does-not-have-top-pollutant';
		}

		if (selected_station_id && site && selected_station_id === siteId) {
			extraClass = extraClass + ' selected';
		} else if (selected_station_id && site && selected_station_id !== siteId) {
			extraClass = extraClass + ' desselected';
		}

		return (
			<Marker
				key={`marker-${index}`}
				longitude={parseFloat(siteLong)}
				latitude={parseFloat(siteLat)}
				offsetTop={-20}
				offsetLeft={-20}
			>
				<SitePin
					extraClass={extraClass}
					size={20}
					aqi={siteAqi}
					stationId={site ? siteId : null}
					idx={index}
				/>
			</Marker>
		);
	};

	render() {
		const { viewport } = this.state;
		const { sites } = this.props;

		return (
			<div className='map-wrapper'>
				<div className='map-legend'>
					<div className='map-legend-item'>
						<div className='map-skittle na'></div>
						<span>AQI Unavailable</span>
					</div>
					<div className='map-legend-item'>
						<div className='map-skittle no-top'></div>
						<span>Top Pollutant Not Measured*</span>
					</div>
				</div>
				<ReactMapGL
					width='100%'
					height='100%'
					{...viewport}
					onViewportChange={viewport => this.onViewportChange(viewport)}
					mapboxApiAccessToken={MAPBOX_TOKEN}
					mapStyle='mapbox://styles/viastudio/cjsdmdk0k11uo1gqbswnnj5vs'
				>
					{sites && sites.valueSeq().map(this.renderSiteMarker)}
				</ReactMapGL>
			</div>
		);
	}
}

const mapStateToProps = store => {
	return {
		sites: store.rootState.getIn(['aqi', 'sites']),
		siteReadings: store.rootState.getIn(['aqi', 'site_readings']),
		pollutantId: store.rootState.getIn(['aqi', 'current', 'ParameterName'])
	};
};

export default connect(mapStateToProps)(StationMap);
