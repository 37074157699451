import { put, takeEvery, call } from 'redux-saga/effects';
import NProgress from 'nprogress';

import {
	GET_SITES,
	fetchAQI,
	updateTopAQI,
	updateSites,
	updateParameters,
	fetchParameters
} from './actions';

export function* handleLoadSites() {
	NProgress.start();

	//Debug for testing different AQI values
	//const newAQI = getQueryAqi();
	// if (newAQI) {
	//     AirQuality.Index = newAQI;
	// }

	let resp = yield call(fetchAQI);
	let { data } = resp.data;

	yield put(updateSites(data.sites));
	yield put(updateTopAQI(data));
	NProgress.done();
}

export function* watchGetSites() {
	yield takeEvery(GET_SITES, handleLoadSites);
}

export function* handleLoadParameters() {
	let resp = yield call(fetchParameters);

	let { data } = resp.data;

	yield put(updateParameters(data));
}
