import React from 'react';
import { connect } from 'react-redux';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTheme } from 'victory';
import { getAQIStrings } from '../modules/AQI/aqi';

const DetailGraph = ({ allPollutantData }) => {
	const data = allPollutantData.toJS().map(rdg => ({
		pollutant: rdg.air_now_mnemonic ? rdg.air_now_mnemonic : rdg.parameter_name,
		aqi: rdg.aqi
	}));

	// calc chart's max Y domain
	const max = 100;

	return (
		<>
			<span className='axis-label'>AQI</span>
			<svg viewBox='0 0 500 500' width='100%' height='100%'>
				<VictoryChart
					width={500}
					height={500}
					standalone={false}
					domain={{ y: [0, max] }}
					domainPadding={{ x: 50, y: 50 }}
					theme={VictoryTheme.material}
				>
					<VictoryAxis
						dependentAxis
						style={{
							axis: { stroke: 'transparent' },
							ticks: { stroke: 'gray' },
							tickLabels: { fontSize: 20, fill: 'gray' }
						}}
					/>
					<VictoryBar
						data={data}
						x='pollutant'
						y='aqi'
						cornerRadius={10}
						style={{
							data: {
								fill: ({ aqi }) => getAQIStrings(aqi).color
							}
						}}
					/>
					<VictoryAxis
						style={{
							axis: { stroke: 'black' },
							grid: { stroke: 'transparent' },
							tickLabels: { fontSize: 25, fill: 'gray' }
						}}
					/>
				</VictoryChart>
			</svg>
		</>
	);
};

const mapStateToProps = store => {
	return {
		allPollutantData: store.rootState.getIn(['aqi', 'all'])
	};
};

export default connect(mapStateToProps)(DetailGraph);
