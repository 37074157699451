import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';

import { rootReducer } from './modules/AirAPI/reducer';

import { browserReducer } from './modules/Browser/reducer';

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
	rootState: rootReducer,
	browserState: browserReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
