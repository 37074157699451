import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';

class PollutantBreakdownPanel extends React.Component {
	constructor(props) {
		super(props);
		this.renderTableRows = this.renderTableRows.bind(this);
	}

	renderTableRows() {
		const { parameters, readings, isToxic } = this.props;

		if (!(parameters && readings)) {
			return;
		}

		let mapCallback = param => {
			let findCallback = rdg => rdg.parameter_name === param.name;

			let reading = this.props.readings.find(findCallback)
				? this.props.readings.find(findCallback)
				: { report_value: 'NA', unit_abbreviation: '' };

			return (
				<tr key={`pol-${param.id}`}>
					<td className='type'>{param.description}:</td>
					<td className='border'>{reading.report_value}</td>
					<td className='border'>{reading.unit_description}</td>
				</tr>
			);
		};

		// sort parameters & split into toxics & non-toxics
		let [toxics, pollutants] = parameters
			.toJS()
			.filter(p => p.category)
			.sort(
				(a, b) =>
					a.category.name.localeCompare(b.category.name) ||
					a.description.localeCompare(b.description)
			)
			.reduce(
				(acc, cur) => {
					cur.is_toxic ? acc[0].push(cur) : acc[1].push(cur);
					return acc;
				},
				[[], []]
			);

		if (isToxic) {
			return toxics.map(mapCallback);
		} else {
			return pollutants.map(mapCallback);
		}
	}

	render() {
		const readings = this.props.readings ? this.props.readings : null;
		const lastUpdate = readings.length > 0 ? dayjs(readings[0].date) : null;

		return (
			<section className='pollutant-breakdown-panel'>
				<div className='header'>
					<h1>
						{this.props.isToxic ? 'Toxics Breakdown' : 'Pollutant Breakdown'}
					</h1>
					<span className='last-reading'>
						Last Reading:&nbsp;
						<time dateTime={lastUpdate && lastUpdate.toISOString()}>
							{lastUpdate && lastUpdate.format('ddd, h A') + ' EST'}
						</time>
					</span>
				</div>
				<div className='container'>
					<h2>A Detailed Breakdown of Data</h2>
					{this.props.isToxic ? (
						<p>
							For more information on Toxics Air Monitoring, please visit APCD’s{' '}
							<a href='https://louisvilleky.gov/government/air-pollution-control-district/air-quality-monitoring'>
								Air Quality Monitoring page
							</a>
						</p>
					) : null}
					<div className='table-wrapper'>
						<table>
							<tbody>
								<tr className='heading'>
									<td className='description-cell'></td>
									<td className='center'>Hourly Value</td>
									<td className='center'>Unit</td>
								</tr>
								{this.renderTableRows()}
							</tbody>
						</table>
					</div>
				</div>
			</section>
		);
	}
}

const mapStateToProps = (store, ownProps) => {
	return {
		allToxics: store.rootState.getIn(['parameters', 'toxics']),
		allPollutants: store.rootState.getIn(['parameters', 'pollutants']),
		isToxic: ownProps.isToxic,
		readings: ownProps.readings,
		parameters: ownProps.parameters
	};
};

export default connect(mapStateToProps)(PollutantBreakdownPanel);
