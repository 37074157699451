import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import StationMap from '../components/StationMap';

export default class MapPanel extends PureComponent {
	render() {
		return (
			<section className='map-panel'>
				<StationMap />
				<div className='map-info'>
					<div className='panel-heading'>
						<h1>Louisville&apos;s AQI Stations</h1>
						<p>
							Air Quality Index determined using&nbsp;
							<a
								href='https://www.airnow.gov/aqi/aqi-basics/using-air-quality-index/'
								target='_blank'
								rel='noreferrer noopener'
							>
								NowCast AQI
							</a>
						</p>
					</div>
					<h3>Select a specific Louisville AQI station to learn more</h3>
					<p>
						The Air Quality Index (AQI) tells how clean or polluted your outdoor
						air is, and what associated health effects might be a concern for
						you. The EPA calculates the AQI for five major air pollutants
						regulated by the Clean Air Act, but the primary focus in Kentuckiana
						is ground-level ozone (O3) and particle pollution (PM2.5).
					</p>
					<Link to={`/station/21-111-1041`}>
						Show me stations measuring Toxics / VOCs / PAMS
					</Link>
					<p className='panel-footer'>
						*Not all stations measure every pollutant. Stations which don&apos;t
						measure the top pollutant from which the overall Louisville Metro
						area AQI has been calculated have been colored blue. Since all
						pollutants are not measured at every site, differences among the
						site-based AQI values may not necessarily indicate changes in
						pollutant concentrations.
					</p>
				</div>
			</section>
		);
	}
}
