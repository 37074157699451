import React from 'react';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTheme } from 'victory';
import { getAQIStrings } from '../modules/AQI/aqi';

const StationGraph = props => {
	const { aqi } = props;

	if (!aqi) {
		return null;
	}

	const data = aqi.map(rdg => ({
		pollutant: rdg.parameter.air_now_mnemonic
			? rdg.parameter.air_now_mnemonic
			: rdg.parameter.name,
		aqi: rdg.aqi
	}));

	let max = 100;

	return (
		<>
			<span className='axis-label'>AQI</span>
			<svg viewBox='0 0 800 400' width='100%' height='100%'>
				<VictoryChart
					width={750}
					height={400}
					standalone={false}
					domain={{ y: [0, max] }}
					domainPadding={{ x: 50, y: 50 }}
					theme={VictoryTheme.material}
				>
					<VictoryAxis
						dependentAxis
						style={{
							axis: { stroke: 'transparent', label: 'AQI' },
							ticks: { stroke: 'gray' },
							tickLabels: { fontSize: 20, fill: 'gray' }
						}}
					/>
					<VictoryBar
						data={data}
						x='pollutant'
						y='aqi'
						cornerRadius={10}
						barWidth={40}
						style={{
							data: {
								fill: ({ aqi }) => getAQIStrings(aqi).color
							}
						}}
					/>
					<VictoryAxis
						style={{
							axis: { stroke: 'black' },
							grid: { stroke: 'transparent' },
							tickLabels: { fontSize: 20, fill: 'gray' }
						}}
					/>
				</VictoryChart>
			</svg>
		</>
	);
};

export default StationGraph;
