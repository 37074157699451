import React from 'react';
import MapPanel from '../components/MapPanel';
import Skittles from '../components/Skittles';
import HistoryGraphPanel from '../components/HistoryGraphPanel';
import HowToHelpPanel from '../components/HowToHelpPanel';
import TopPollutant from '../components/TopPollutant';
import DetailGraph from '../components/DetailGraph';
import DetailMessages from '../components/DetailMessages';
import DidYouKnowMsg from '../components/DidYouKnowMsg';

export default class DetailView extends React.Component {
	render() {
		return (
			<>
				<section className='main-panel'>
					<div className='aqi-info'>
						<h1>Louisville&#39;s Air Quality Index</h1>
						<div className='aqi-info-content'>
							<div className='aqi-details'>
								<div className='detail-graph'>
									<DetailGraph />
								</div>
								<TopPollutant />
							</div>
							<DidYouKnowMsg />
							<Skittles />
						</div>
					</div>
					<div className='aqi-extra-details detail-messages'>
						<DetailMessages />
					</div>
				</section>
				<MapPanel />
				<HowToHelpPanel />
				<HistoryGraphPanel />
			</>
		);
	}
}
