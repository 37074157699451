const ACTION_GO_PLAY = 'Go Play!';
const ACTION_CHECK_BACK =
	'<span class="action-warning">CHECK BACK WITH US.</span> You should be OK, but keep an eye out for shortness of breath or coughing.';
const ACTION_REDUCE_EVERYONE =
	'<span class="action-warning">REDUCE PROLONGED or HEAVY</span> outdoor exertion. Take it down a notch, and take more breaks.';
const ACTION_REDUCE_SENSITIVE =
	'<span class="action-warning">REDUCE PROLONGED or HEAVY</span> outdoor exertion.';
const ACTION_AVOID_EVERYONE =
	'<span class="action-warning">AVOID PROLONGED or HEAVY</span> outdoor exertion. Might be a good day to play or work out indoors.';
const ACTION_AVOID_SENSITIVE =
	'<span class="action-warning">AVOID PROLONGED or HEAVY</span> outdoor exertion. Take more breaks, do less intense activities. Be watchful for symptoms such as coughing or shortness of breath. Asthma sufferers should keep their medications handy. ';
const ACTION_AVOID =
	'<span class="action-warning">AVOID ALL</span> activity outdoors.';
const ACTION_MEDS = 'Keep quick relief medications handy!';
const ACTION_NO = "DON'T GO OUTSIDE.";

export const aqi_strings = {
	good: {
		title: 'Good',
		status: 'good',
		color: '#00e400',
		desc:
			'Air quality is considered satisfactory, and air pollution poses little or no risk.',
		friendly_desc:
			"It's a great day to be outside (or at least open the windows!)",
		actions: [
			{ type: 'everyone', action: ACTION_GO_PLAY },
			{ type: 'sensitive', action: ACTION_GO_PLAY }
		]
	},
	moderate: {
		title: 'Moderate',
		status: 'moderate',
		color: '#ffff00',
		desc:
			'Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution.',
		friendly_desc:
			"It's a good day to be outside for most everyone! (However, those unusually sensitive to the dominant pollutants should be watchful.)",
		actions: [
			{ type: 'everyone', action: ACTION_GO_PLAY },
			{ type: 'sensitive', action: ACTION_CHECK_BACK }
		]
	},
	low: {
		title: 'Unhealthy for Sensitive Groups',
		status: 'low',
		color: '#ff7e00',
		desc:
			'Members of sensitive groups may experience health effects. The general public is not likely to be affected.',
		friendly_desc:
			"Most people won't notice the higher levels of pollutants - but if you are sensitive, it's a good day to watch the air quality, and keep an eye out for symptoms such as coughing or shortness of breath.",
		actions: [
			{ type: 'everyone', action: ACTION_CHECK_BACK },
			{ type: 'sensitive', action: ACTION_REDUCE_SENSITIVE },
			{ type: 'asthma', action: ACTION_MEDS }
		]
	},
	unhealthy: {
		title: 'Unhealthy',
		status: 'unhealthy',
		color: '#ff0000',
		desc:
			'Everyone may begin to experience health effects; members of sensitive groups may experience more serious health effects.',
		friendly_desc:
			'The air quality is unhealthy, and everyone may experience health effects. If you are in a sensitive group, you may experience serious health effects.',
		actions: [
			{ type: 'everyone', action: ACTION_REDUCE_EVERYONE },
			{ type: 'sensitive', action: ACTION_AVOID_SENSITIVE },
			{ type: 'asthma', action: ACTION_MEDS }
		]
	},
	bad: {
		title: 'Very Unhealthy',
		status: 'bad',
		color: '#99004c',
		desc: 'Health alert: everyone may experience more serious health effects.',
		friendly_desc:
			'Very unhealthy today. <em>Everyone</em> may experience serious health effects. Modify your outdoor plans!',
		actions: [
			{ type: 'everyone', action: ACTION_AVOID_EVERYONE },
			{ type: 'sensitive', action: ACTION_AVOID },
			{ type: 'asthma', action: ACTION_MEDS }
		]
	},
	hazardous: {
		title: 'Hazardous',
		status: 'hazardous',
		color: '#7e0023',
		desc:
			'Health warnings of emergency conditions. The entire population is more likely to be affected.',
		friendly_desc:
			'Hazardous - everyone should limit their exposure to outdoor air.',
		actions: [
			{ type: 'everyone', action: ACTION_NO },
			{ type: 'sensitive', action: ACTION_NO },
			{ type: 'asthma', action: ACTION_MEDS }
		]
	}
};

export const getAQIStrings = aqi => {
	if (aqi >= 0 && aqi <= 50) {
		return aqi_strings.good;
	} else if (aqi >= 51 && aqi <= 100) {
		return aqi_strings.moderate;
	} else if (aqi >= 101 && aqi <= 150) {
		return aqi_strings.low;
	} else if (aqi >= 151 && aqi <= 200) {
		return aqi_strings.unhealthy;
	} else if (aqi >= 201 && aqi <= 300) {
		return aqi_strings.bad;
	} else if (aqi >= 301) {
		return aqi_strings.hazardous;
	}
};
