import React from 'react';
import { connect } from 'react-redux';

const DetailMessages = ({ status, effects, actions }) => {
	const action = !actions
		? null
		: typeof actions === 'string'
		? actions
		: status === 'good'
		? actions.get('good')
		: status === 'moderate'
		? actions.get('moderate')
		: actions.get('high');

	return (
		<div className='action-details'>
			<h2>What Contributes</h2>
			<p>{effects}</p>
			<h2>What Can You Do?</h2>
			<p>{action}</p>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		effects: store.rootState.getIn(['aqi', 'pollutant_strings', 'effects']),
		actions: store.rootState.getIn(['aqi', 'pollutant_strings', 'actions'])
	};
};

export default connect(mapStateToProps)(DetailMessages);
