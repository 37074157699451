import React from 'react';
import GeneralMessage from '../components/GeneralMessage';
import SensitivePopulationPanel from '../components/SensitivePopulationPanel';
import MapPanel from '../components/MapPanel';
import Skittles from '../components/Skittles';
import HistoryGraphPanel from '../components/HistoryGraphPanel';
import HowToHelpPanel from '../components/HowToHelpPanel';
import AQI_StatusMarker from '../components/AQI_StatusMarker';
import TopPollutant from '../components/TopPollutant';
import DidYouKnowMsg from '../components/DidYouKnowMsg';

export default class BasicView extends React.Component {
	render() {
		return (
			<>
				<section className='main-panel'>
					<div className='aqi-info'>
						<h1>Louisville&#39;s Air Quality Index</h1>
						<div className='aqi-info-content'>
							<div className='aqi-details'>
								<div className='marker-wrapper'>
									<AQI_StatusMarker />
								</div>
								<TopPollutant />
							</div>
							<DidYouKnowMsg />
							<Skittles />
						</div>
					</div>
					<div className='aqi-actions'>
						<h1>What This Means For You</h1>
						<div className='action-details'>
							<h2>For Everyone:</h2>
							<GeneralMessage />
							<h2>Sensitive Populations:</h2>
							<SensitivePopulationPanel />
						</div>
					</div>
				</section>
				<MapPanel />
				<HowToHelpPanel />
				<HistoryGraphPanel />
			</>
		);
	}
}
