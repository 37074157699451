export const MAPBOX_TOKEN =
	'pk.eyJ1Ijoidmlhc3R1ZGlvIiwiYSI6ImNpaG00cWdpZDBvYnl2cWtsbGNseTZvZGIifQ.F3e51CX_ng50CnhLabntiA';

export const mapBounds = {
	latitude: {
		min: 37.715926,
		max: 38.61401
	},
	longitude: {
		max: -84.774293,
		min: -86.506992
	},
	zoom: {
		min: 8
	}
};
