import { all } from 'redux-saga/effects';

import {
	handleLoadSites,
	watchGetSites,
	handleLoadParameters
} from './modules/AirAPI/sagas';

import { watchGetUserLocation } from './modules/Browser/sagas';

export default function* rootSaga() {
	yield all([
		handleLoadSites(),
		watchGetSites(),
		watchGetUserLocation(),
		handleLoadParameters()
	]);
}
