import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Helmet } from 'react-helmet';

import { cleanSiteDescription } from '../modules/AirAPI/actions';

const Header = ({ title, status, site_locations }) => {
	site_locations = site_locations.toJS();

	let pageTitle = 'Louisville Air Watch';
	if (title) {
		pageTitle = `${title} Air Quality`;
	}

	const path = `assets/img/favicons/${status}`;

	const renderStateLocations = locations => {
		return (
			<>
				{locations.map((location, idx) => {
					return (
						<LinkContainer
							to={`/station/${location.aqs_site_id}`}
							key={`loc_${idx}`}
						>
							<NavDropdown.Item>
								{cleanSiteDescription(location.site_description)}
							</NavDropdown.Item>
						</LinkContainer>
					);
				})}
			</>
		);
	};

	const renderStationsMenu = () => {
		if (site_locations.length <= 0) {
			return <Nav.Link>Air Monitoring Stations</Nav.Link>;
		}

		return (
			<NavDropdown
				title='Air Monitoring Stations'
				id='navbar-dropdown'
				className='navbar-stations'
			>
				{Object.keys(site_locations).map((state, idx) => {
					return (
						<span key={`menu_${idx}`}>
							<Nav.Item>{state}</Nav.Item>
							{renderStateLocations(site_locations[state])}
							<NavDropdown.Divider />
						</span>
					);
				})}
			</NavDropdown>
		);
	};

	return (
		<>
			<Helmet titleTemplate='%s | Louisville, Kentucky Air Pollution Control District (APCD)'>
				<title>{pageTitle}</title>
				<link rel='icon' href={`${path}/favicon.ico`} />
				<link rel='icon' sizes='16x16' href={`${path}/favicon-16x16.png`} />
				<link rel='icon' sizes='32x32' href={`${path}/favicon-32x32.png`} />
				<link
					rel='apple-touch-icon-precomposed'
					href={`${path}/apple-touch-icon.png`}
				/>
			</Helmet>

			<Navbar expand='lg' fixed='top'>
				<LinkContainer to='/basic'>
					<Navbar.Brand>Louisville Air Watch</Navbar.Brand>
				</LinkContainer>
				<Navbar.Toggle aria-controls='navbar' />
				<Navbar.Collapse id='navbar' className='justify-content-end'>
					<Nav>
						{renderStationsMenu()}
						<Nav.Link href='https://louisvilleky.gov/government/air-pollution-control-district/louisville-air-watch-faqs'>
							FAQs
						</Nav.Link>
						<Nav.Link href='https://louisvilleky.gov/government/air-pollution-control-district'>
							About the APCD
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>
	);
};

const mapStateToProps = store => {
	return {
		title: store.rootState.getIn(['aqi', 'aqi_strings', 'title']),
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		site_locations: store.rootState.get('site_locations')
	};
};

export default connect(mapStateToProps)(Header);
