import { fromJS } from 'immutable';

import {
	UPDATE_CURRENT_AQI,
	UPDATE_TOP_AQI,
	UPDATE_SITES,
	UPDATE_PARAMETERS,
	calcState
} from './actions';

import { getAQIStrings } from '../AQI/aqi';

import { getPollutantStrings } from '../AQI/pollutants';

const initialState = fromJS({
	aqi: {
		top: {},
		all: [],
		current: {},
		aqi_strings: {},
		pollutant_strings: {},
		sites: {}
	},
	sites: [],
	site_locations: [],
	parameters: {
		toxics: [],
		pollutants: []
	}
});

export function rootReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_CURRENT_AQI: {
			return state.setIn(['aqi', 'current'], fromJS(action.aqi));
		}

		case UPDATE_TOP_AQI: {
			const { aqi } = action;

			const topAQI =
				aqi.aqi.length > 0
					? aqi.aqi[0]
					: {
							aqi: 'NA',
							parameter_name: 'NA'
					  };

			return state
				.setIn(['aqi', 'top'], fromJS(topAQI))
				.setIn(['aqi', 'all'], fromJS(aqi.aqi))
				.setIn(['aqi', 'current', 'Index'], topAQI.aqi)
				.setIn(['aqi', 'current', 'ParameterName'], topAQI.parameter_name)
				.setIn(['aqi', 'aqi_strings'], fromJS(getAQIStrings(topAQI.aqi)))
				.setIn(
					['aqi', 'pollutant_strings'],
					fromJS(getPollutantStrings(topAQI.parameter_name))
				)
				.setIn(['aqi', 'sites'], fromJS(aqi.sites))
				.setIn(['aqi', 'site_readings'], fromJS(aqi.site_aqi));
		}

		case UPDATE_SITES: {
			let sites = action.sites;
			let site_locations = {};

			sites.map(site => {
				const {
					aqs_site_id,
					site_description,
					street_address_1,
					street_address_2,
					zip_code
				} = site;
				const state = calcState(zip_code);

				sites[aqs_site_id] = { ...site };

				if (!site_locations.hasOwnProperty(state)) {
					site_locations[state] = [];
				}

				site_locations[state].push({
					aqs_site_id,
					site_description,
					street_address_1,
					street_address_2
				});
			});

			for (let state in site_locations) {
				let location = site_locations[state];

				location.sort((a, b) => {
					return ('' + a.site_description).localeCompare(b.site_description);
				});
			}

			return state
				.set('sites', fromJS(sites))
				.set('site_locations', fromJS(site_locations));
		}

		case UPDATE_PARAMETERS: {
			let [toxics, pollutants] = action.parameters
				.filter(p => p.category)
				.sort(
					(a, b) =>
						a.category.name.localeCompare(b.category.name) ||
						a.description.localeCompare(b.description)
				)
				.reduce(
					(arr, cur) => {
						cur.is_toxic ? arr[0].push(cur) : arr[1].push(cur);
						return arr;
					},
					[[], []]
				);

			return state
				.setIn(['parameters', 'toxics'], fromJS(toxics))
				.setIn(['parameters', 'pollutants'], fromJS(pollutants));
		}

		default:
			return state;
	}
}
