import { fromJS } from 'immutable';

import { UPDATE_USER_LOCATION } from './actions';

const initialState = fromJS({
	pos: null
});

export function browserReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_USER_LOCATION: {
			const {
				coords: { latitude, longitude }
			} = action.pos;

			return state.set('pos', fromJS({ latitude, longitude }));
		}

		default:
			return state;
	}
}
