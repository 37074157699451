import React from 'react';
import { connect } from 'react-redux';

const AQIStatusMarker = ({ aqi, status }) => {
	return (
		<div className={`aqi-marker ${status}`}>
			<div className='aqi-marker-fg'>
				<p>AQI: {aqi}</p>
			</div>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		aqi: store.rootState.getIn(['aqi', 'current', 'Index']),
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status'])
	};
};

export default connect(mapStateToProps)(AQIStatusMarker);
