import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import store from '../store';

import UIKit from './UIKit';
import BasicView from './BasicView';
import DetailView from './DetailView';
import StationView from './StationView';

import Header from '../components/Header';
import SectionHeader from '../components/SectionHeader';

export default class App extends React.Component {
	render() {
		return (
			<Provider store={store}>
				<Router>
					<Header />
					<main>
						<SectionHeader />
						<Route exact path='/' component={BasicView} />
						<Route path='/basic' component={BasicView} />
						<Route path='/details' component={DetailView} />
						<Route path='/station/:station_id' component={StationView} />
						<Route path='/ui-kit' component={UIKit} />
					</main>
				</Router>
			</Provider>
		);
	}
}
