import React from 'react';
import { connect } from 'react-redux';

const GeneralMessage = ({ status, friendly_desc }) => {
	const renderDesc = () => (
		<span dangerouslySetInnerHTML={{ __html: friendly_desc }} />
	);

	return (
		<div className='general-msg-wrap'>
			<div className={`${status} general-msg`}>{renderDesc()}</div>
		</div>
	);
};

const mapStateToProps = store => {
	return {
		status: store.rootState.getIn(['aqi', 'aqi_strings', 'status']),
		friendly_desc: store.rootState.getIn([
			'aqi',
			'aqi_strings',
			'friendly_desc'
		])
	};
};

export default connect(mapStateToProps)(GeneralMessage);
