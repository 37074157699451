import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';

import App from './views/App';

const history = createBrowserHistory();

let app = document.getElementById('app');
if (app) {
	const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

	render(<App />, app);
}
